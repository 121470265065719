<template>
  <div class="opportunities-wrapper">
    <div class="header">
      <span class="heading">Activities</span>
    </div>
    <template v-if="activities.length">
      <table class="table table-striped table-hover activities-list">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column">{{ column }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in activities"
            :key="index"
            @click="$router.push('/profile/' + item.profile._id)"
          >
            <td class="name-column">
              <signed-image
                v-if="
                  item.profilePhoto &&
                  typeof item.profile.profilePhoto === 'object' &&
                  item.profile.hasProfileViewAccess
                "
                :file="item.profile.Photo"
                :alt="item.profile.name"
              />
              <img v-else src="/img/icons/jobseeker.svg" alt="" />
              <div class="name">
                {{
                  item.profile.hasProfileViewAccess
                    ? item.profile.name
                    : `Jobseeker ${item.profile._id.slice(0, 4)}`
                }}
                <span v-if="isMatch(item.profile._id)">Matched job seeker</span>
              </div>
            </td>
            <td class="status">
              <span :class="item.type">{{ statuses[item.type] }}</span>
            </td>
            <td>{{ getFormattedDate(item.date) }}</td>
          </tr>
        </tbody>
      </table>

      <!--- mobile view --->
      <div class="activities-list-mobile mobile-cards-wrapper">
        <div
          v-for="(item, index) in activities"
          :key="index"
          class="activity-wrapper mobile-card"
        >
          <div class="img">
            <img src="/img/icons/jobseeker.svg" alt="" />
          </div>
          <div class="company-name">
            {{ item.profile.name }}
          </div>
          <div class="match-tag" v-if="isMatch(item.profile._id)">
            Matched job seeker
          </div>
          <div class="dates-wrapper">
            <div class="status">
              <span :class="item.type">{{ statuses[item.type] }}</span>
            </div>
            <div class="date">
              <span class="date-header">Last Activity</span>
              {{ getFormattedDate(item.date) }}
            </div>
          </div>
        </div>
      </div>
      <!--- mobile view end --->
    </template>
    <div class="no-data" v-else>
      <img src="/img/icons/no-data.svg" alt="" />
      <div class="info">No activities at this time</div>
      <div class="additional-info">
        Check back later, or try searching for employees within the
        <span class="link" @click="$router.push(`/employees/jobs`)"
          >Find Employees</span
        >
        page.
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"
export default {
  name: "job-activities",
  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: ["Job Seeker", "Status", "Last Activity"],
      statuses: {
        "job-match": "Job Match",
        "new-application": "New Applicant",
        "unlocked-applicant": "Profile Unlocked",
        "sent-job-post": "Job Post Sent",
        "application-accepted": "Application Accepted",
        "application-rejected": "Application Rejected",
      },
    }
  },
  methods: {
    getFormattedDate(dateString) {
      const parsedDate = moment(dateString)

      const currentDate = moment()

      const diffInDays = currentDate.diff(parsedDate, "days")

      // Determine the display format based on the difference in days
      let displayDate
      if (diffInDays === 0) {
        displayDate = "Today at " + parsedDate.format("h:mm A")
      } else if (diffInDays === 1) {
        displayDate = "Yesterday at " + parsedDate.format("h:mm A")
      } else {
        displayDate = parsedDate.format("DD MMM YYYY [at] hh:mm A")
      }
      return displayDate
    },
    isMatch(id) {
      let isMatch = this.activities.filter((item) => {
        return item.type == "job-match" && item.profile._id == id
      })

      return isMatch.length ? true : false
    },
  },
}
</script>
<style lang="scss">
.opportunities-wrapper {
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .heading {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      text-transform: none;
    }

    .edit-btn {
      display: flex;
      padding: 10px 24px 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 2px solid var(--gfb-blue-brand-500, #0095e2);
      background: var(--White, #fff);
      font-size: 14px;
      color: var(--gfb-blue-brand-500, #0095e2);
      font-weight: 600;
      cursor: pointer;
    }
  }

  table {
    border-radius: 18px;
    background: var(--Gray-100, #eceef2);
    padding: 16px 8px 8px 8px;
    border: none;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;

    thead {
      display: table;
      width: 100%;
      table-layout: fixed;

      th {
        color: var(--Gray-900, #343946);
        font-size: 16px;
        letter-spacing: 0;
        font-style: normal;
        font-weight: 600;
        text-transform: none;
      }
    }

    tbody {
      display: block;
      //max-height: 100vh;
      overflow: auto;
    }

    tbody tr {
      border-bottom: 1px solid var(--Gray-200, #d5d9e2);
      display: table;
      width: 100%;
      table-layout: fixed;
      cursor: pointer;

      &:last-child {
        border-bottom: unset;
      }

      td {
        color: var(--Gray-800, #3a4252);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        vertical-align: middle;

        img {
          margin-right: 16px;
        }

        &.name-column {
          display: flex;
          align-items: center;
          .name {
            font-weight: 600;
            display: flex;
            flex-direction: column;

            span {
              color: var(--Gray-500, #677690);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }

        &.status {
          span {
            display: flex;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 600;
            width: fit-content;

            &.application-accepted {
              background: var(--gfb-blue-brand-200, #7cd2ff);
              color: var(--gfb-blue-brand-600, #0073af);
            }

            &.application-rejected {
              background: #fbcdcd;
              color: #e24646;
            }

            &.new-application {
              background: var(--gfb-orange-brand-50, #ffe2b0);
              color: var(--gfb-orange-brand-500, #f77f00);
            }

            &.unlocked-applicant {
              background: var(--Green-200, #b8fada);
              color: #10854f;
            }

            &.sent-job-post {
              border: 1px solid var(--Gray-500, #677690);
              color: var(--Gray-600, #525e77);
            }

            &.job-match {
              background: #ffea99;
              color: var(--Gray-600, #cca300);
            }
          }
        }
      }

      &:first-child td:first-child {
        border-top-left-radius: 12px;
      }
      &:first-child td:last-child {
        border-top-right-radius: 12px;
      }

      &:last-child td:first-child {
        border-bottom-left-radius: 12px;
      }
      &:last-child td:last-child {
        border-bottom-right-radius: 12px;
      }

      &:first-child td {
        border-top-style: solid;
      }
    }

    tbody tr:nth-of-type(odd) {
      background-color: #fff;
    }

    tbody tr:nth-of-type(even) {
      background-color: var(--Gray-50, #f6f7f9);
    }
  }

  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 112px;

    img {
      width: 250px;
    }

    .info {
      color: var(--Gray-900, #343946);
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      padding-top: 24px;
      padding-bottom: 8px;
    }

    .additional-info {
      color: var(--Gray-600, #525e77);
      text-align: center;
      font-size: 16px;
      font-weight: 400;

      .link {
        color: var(--gfb-blue-brand-500, #0095e2);
        font-weight: 600;
      }
    }
  }
}

.activities-list-mobile {
  display: none;
}

@media (max-width: 768px) {
  .activities-list {
    display: none;
  }

  .activities-list-mobile {
    display: flex;
  }

  .no-data {
    padding: 25px !important;

    img {
      max-width: 200px;
    }
  }
}

@media (max-width: 480px) {
  .no-data {
    padding: 10px !important;

    img {
      max-width: 150px;
    }
  }
}
</style>
