<template>
  <div class="stats-display">
    <div
      class="icon-background"
      :style="{ 'background-color': iconBackground }"
    >
      <component
        v-if="icons[icon]"
        v-bind:is="icons[icon]"
        :color="iconColor"
      />
      <img v-else :src="`/img/icons/${icon}-icon.svg`" alt="" />
    </div>
    <div class="stats">
      <span class="total-stats">{{ stats }}</span>
      <span class="title">{{ title }}</span>
    </div>
  </div>
</template>
<script>
import { PhTrayArrowDown, PhPaperPlaneTilt } from "phosphor-vue"
export default {
  name: "stats-card",
  components: {
    PhTrayArrowDown,
    PhPaperPlaneTilt,
  },
  props: {
    icon: String,
    title: String,
    stats: Number,
    iconBackground: String,
    iconColor: String,
  },
  data() {
    return {
      icons: {
        applied: "PhPaperPlaneTilt",
      },
    }
  },
}
</script>
<style scoped>
.total-stats {
  color: var(--Gray-900, #343946);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-right: 20px;
}

.stats {
  display: flex;
  align-items: center;
  padding: 15px;
}

.title {
  color: var(--Gray-500, #677690);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.icon-background {
  display: flex;
  align-items: center;
  min-width: 30%;
  border-radius: 12px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 50px;
}

.stats-display {
  display: flex;
  border-radius: 5px;
  border-radius: 12px;
  border: 1px solid var(--Blue-50, #d6e1f3);
  height: 90px;
  margin-bottom: 30px;
}

.stats-display svg,
.stats-display img {
  height: 100%;
  width: 100%;
  margin-right: 30%;
}
</style>
