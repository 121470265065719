<template>
  <div class="wrapper">
    <div class="header-section">
      <div class="left">
        <span class="heading">{{
          isJobSeeker ? "Job Matching" : "Employee Matching"
        }}</span>
        <p class="description">
          {{
            isJobSeeker
              ? "Add trade categories of the jobs you are looking for and we will match you with potential job opportunities. Your contact information is always private until you apply."
              : "Add trade categories that best match the job seekers you need and we will recruit them directly to you."
          }}
        </p>
      </div>
      <div class="toggle-status-switch right" v-if="isJobSeeker">
        <span class="toggle-status-label"> Send me opportunities </span>
        <toggle-button
          v-model="selected"
          @change="handleEditProfile"
          :css-colors="true"
          :labels="false"
        />
      </div>
    </div>
    <div class="inner-wrapper">
      <ProfileSubSectionHeader
        :title="
          isJobSeeker
            ? 'Trade jobs we are matching you with'
            : 'Trade categories we are matching you with among job seekers'
        "
        icon="trade-experience-icon"
        :showEdit="true"
        @toggle-edit="handleModalDisplay"
      />
      <capsule-list :items="interests" :blue="true" :highlighted="true" />
    </div>
    <default-modal
      description="Please select the interested categories"
      title="Add New Skill"
      @onConfirm="handleModalConfirm()"
      @onCancel="handleModalCancel()"
      id="add-interested-cat-modal"
      :showCancel="true"
    >
      <template v-slot:input>
        <CustomMultiSelect
          :selectOptions="categoryOptions"
          :selectedList="interests"
          selectLabel="Search or select category"
          @update-selected="updateExperiences"
        ></CustomMultiSelect>
      </template>
    </default-modal>
    <public-status-modal
      :title="
        !selected ? 'Turn off Opportunities?' : 'Ready to Receive Offers?'
      "
      :body="
        !selected
          ? 'You will not receive job opportunities sent directly to you by companies until you turn this back on.'
          : 'Businesses are prepared to invest in contacting you, please only select YES below if you are prepared to receive potential job offers, schedule interviews, and make a career transition'
      "
      @update-status="updatePublicStatus"
      @modal-hide="handleModalHide"
    >
    </public-status-modal>
  </div>
</template>
<script>
import ProfileSubSectionHeader from "@/components/Profile/ProfileSubSectionHeader.vue"
import options from "@/utils/options"
import CustomMultiSelect from "@/components/CustomMultiSelect"
import PublicStatusModal from "@/components/Modals/PublicStatusModal"

export default {
  name: "interested-categories",
  components: { ProfileSubSectionHeader, CustomMultiSelect, PublicStatusModal },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      interests: [],
      categoryOptions: options.categoryOptions,
      selected: this.$store.state.userProfile.public,
      isJobSeeker: true,
    }
  },
  methods: {
    handleModalDisplay() {
      this.$bvModal.show("add-interested-cat-modal")
    },
    handleModalCancel() {
      if (this.isJobSeeker) {
        this.interests =
          this.profile.jobSeeker.interestedCategories &&
          this.profile.jobSeeker.interestedCategories.length
            ? this.profile.jobSeeker.interestedCategories
            : this.handsOnExperienceCategories
      } else {
        this.interests = this.profile.selfPerformWorkCategories
      }
    },
    handleModalHide() {
      window.location.reload()
    },
    async handleModalConfirm() {
      try {
        let userProfile = JSON.parse(
          JSON.stringify(this.$store.state.userProfile)
        )
        if (this.isJobSeeker) {
          userProfile.jobSeeker.interestedCategories = this.interests
          await this.$store.dispatch("api/updateProfile", {
            profileId: userProfile._id,
            data: { ...userProfile },
          })
        } else {
          userProfile.selfPerformWorkCategories = this.interests
          await this.$store.dispatch("api/updateCompanyProfile", {
            profileId: userProfile._id,
            data: { ...userProfile },
          })
        }
        this.$bvModal.hide("add-interested-cat-modal")
      } catch (error) {
        console.log("error", error)
        this.logDevError(error)
      }
    },
    selectExperience(option) {
      let experiences = [...this.interests]
      experiences.push(option)
      this.interests = [...experiences]
    },
    removeExperience(option) {
      this.interests = this.interests.filter(
        (experience) => experience !== option
      )
    },
    updateExperiences(selected) {
      this.interests = selected
    },
    async updatePublicStatus() {
      try {
        let profile = { ...this.$store.state.userProfile }
        profile.public = this.selected
        await this.$store.dispatch("api/updateProfile", {
          profileId: profile.id,
          data: { ...profile },
        })

        let payload = {
          jobseekerId: this.$store.state.userProfile._id,
          userType: this.$store.state.userProfile.type,
          activityType: "public-status-update",
          publicStatus: this.selected,
        }
        this.$store.dispatch("api/createLog", payload)

        this.$bvModal.hide("public-status-modal")
        this.$forceUpdate()
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    },
    handleEditProfile() {
      this.$bvModal.show("public-status-modal")
    },
  },
  created() {
    this.isJobSeeker =
      this.$store.state.userProfile.type == "jobSeeker" ? true : false
    console.log(this.profile)

    if (this.isJobSeeker) {
      this.interests =
        this.profile.jobSeeker.interestedCategories &&
        this.profile.jobSeeker.interestedCategories.length
          ? this.profile.jobSeeker.interestedCategories
          : this.handsOnExperienceCategories
    } else {
      this.interests = this.profile.selfPerformWorkCategories
    }
  },
  computed: {
    handsOnExperienceCategories() {
      return this.profile.jobSeeker.handsOnExperiences.map(
        (experience) => experience.category
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 32px;
  border-radius: 12px;
  border: 1px solid var(--gfb-blue-brand-500, #0095e2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  background-color: #fff;

  .inner-wrapper {
    background: var(--Gray-50, #f6f7f9);
    padding: 16px;
    border-radius: 8px;
    width: 100%;

    .row {
      margin: 0 !important;
    }

    .sub-section-title-wrapper {
      margin-top: -8px !important;

      .sub-section-title {
        justify-content: space-between;

        .edit-btn {
          position: unset;
        }
      }

      .capsule {
        background: var(--gfb-blue-brand-400, #16afff) !important;
        color: #fff !important;
        font-weight: 400 !important;
      }
    }
  }

  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .left {
      width: 65%;
      .heading {
        color: var(--Gray-900, #343946);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        text-transform: none;
      }

      .description {
        color: var(--Gray-800, #3a4252);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 768px) {
    .header-section {
      flex-direction: column;

      .left {
        width: 100%;
      }
    }
  }
}
</style>
